import { IForm } from "../model/Form.model"
import { enumFormDocumentTypeANIP, enumFormDocumentTypeAttestationCPAM,
    enumFormDocumentTypeBulletin,
    enumFormDocumentTypeCGAssistance,
    enumFormDocumentTypeCniRecto,
    enumFormDocumentTypeCniVerso,
    enumFormDocumentTypeGuideSubscriber,
    enumFormDocumentTypeIPID,
    enumFormDocumentTypeIPIDS,
    enumFormDocumentTypeKbis,
    enumFormDocumentTypeMandatPrelevement,
    enumFormDocumentTypeMedecinDirectBrochure,
    enumFormDocumentTypeRecueilBesoin,
    enumFormDocumentTypeRIB, 
    enumFormDocumentTypeRM, 
    enumFormDocumentTypeRMS, 
    enumFormDocumentTypeSubscriptionCertificat,
    enumFormDocumentTypeTG} from "../utils";


export interface IFormDocument {
    id?: number;
    dateCreated?: Date;
    techDateModification?: Date;
    form: IForm;
    filePath?: string;
    fileName?: string;
    fileNameOrigin?: string;
    fileExtension?: string;
    fileTypeMIME?: string;
    type: string;
    isSigned?: boolean;
}


export const getDocumentTypeValue = (type: string) => {
    switch (type) {
        case enumFormDocumentTypeSubscriptionCertificat: return 110;
        case enumFormDocumentTypeBulletin: return 100;
        case enumFormDocumentTypeRecueilBesoin: return 90;
        case enumFormDocumentTypeMandatPrelevement: return 80;
        case enumFormDocumentTypeGuideSubscriber: return 79;
        case enumFormDocumentTypeANIP: return 78;
        case enumFormDocumentTypeMedecinDirectBrochure: return 77;
        case enumFormDocumentTypeIPID: return 76;
        case enumFormDocumentTypeIPIDS: return 75;
        case enumFormDocumentTypeCGAssistance: return 74;
        case enumFormDocumentTypeRM: return 73;
        case enumFormDocumentTypeRMS: return 72;
        case enumFormDocumentTypeTG: return 71;
        case enumFormDocumentTypeCniRecto: return 29;
        case enumFormDocumentTypeCniVerso: return 28;
        case enumFormDocumentTypeKbis: return 27;
        case enumFormDocumentTypeAttestationCPAM: return 26;
        case enumFormDocumentTypeRIB: return 25;
        default: return 0;
    }
}

export const documentOrderByType = (a: IFormDocument, b: IFormDocument) => {

    const aTypeValue = getDocumentTypeValue(a.type);
    const bTypeValue = getDocumentTypeValue(b.type);

    if (aTypeValue > bTypeValue)
        return -1;

    if (aTypeValue < bTypeValue)
        return 1;

    return 0;
}
