import { useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { useTheme } from '@mui/material/styles';

import * as Sentry from '@sentry/react';

import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button"
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Typography from "@mui/material/Typography"

import { default as MUICloseIcon} from '@mui/icons-material/Close';

import { CloseFullIcon } from '../Icon/CloseFullIcon';
import { CheckFullIcon } from '../Icon/CheckFullIcon';

import { uploadFormDocument } from "../../api/FormDocument.api"

import { IForm } from '../../model/Form.model';
import { IFormDocument } from "../../model/FormDocument.model"
import Box from '@mui/material/Box';


interface Props {
    plateformStyle: any;
    children: string;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    document: IFormDocument;
    form: IForm;
    send: (doc: IFormDocument) => void;
}


export const ButtonAddFile = (props: Props) => {

    const theme = useTheme()
    const plateformStyle: any = props.plateformStyle;

    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string>("Format de fichier invalide.");

    const disabled: boolean = props.disabled !== undefined && props.disabled
    const readOnly: boolean = (props.disabled !== undefined && props.disabled) || (props.readOnly !== undefined && props.readOnly)

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    const { open, getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/pdf': [],
            'image/bmp': [],
            'image/jpg': [],
            'image/jpeg': [],
            'image/png': []
        },
        maxFiles: 1,
        maxSize: 10000000,
        disabled: (disabled || readOnly),
        onDrop: files => {
            if (files.length <= 0)
                return;

            let doc: IFormDocument = {...props.document};

            doc.fileNameOrigin = files[0].name;
            doc.fileTypeMIME = files[0].type;

            const formData = new FormData();
            formData.append('file', files[0]);

            if (!props.form.id)
                return;

            uploadFormDocument(doc, props.form.id, formData)
            .then((response: IFormDocument) => {
                props.send(response);  // Update Form states.
            })
            .catch(_ => {
                _.then((error: any) => {
                    if (error?.status === 400 && error.detail)
                        setSnackBarMessage(error.detail)
                    else
                        setSnackBarMessage("Erreur lors de l'envoi du document.")
                })

                Sentry.captureMessage("uploadFormDocument - Exception",
                    {
                        level: 'error',
                        extra: {
                            response: _.toString(),
                            form: props.form.id,
                            filename: doc.fileName,
                            filenameOrigin: doc.fileNameOrigin,
                            extension: doc.fileExtension,
                            typeMime: doc.fileTypeMIME,
                            path: doc.filePath,
                        }
                    }
                )
                setIsSnackbarOpened(true);
            })
        },
        onDropRejected: files => {
            if (files[0].errors.find(_ => _.code === ErrorCode.FileInvalidType) !== undefined)
                setSnackBarMessage("Format de fichier invalide.")
            else if (files[0].errors.find(_ => _.code === ErrorCode.FileTooLarge) !== undefined)
                setSnackBarMessage("Fichier trop volumineux (10Mo maximum).")
            else if (files[0].errors.find(_ => _.code === ErrorCode.TooManyFiles) !== undefined)
                setSnackBarMessage("Un seul fichier autorisé.")
            else
                setSnackBarMessage("Fichier invalide.")

            Sentry.captureMessage("onDropRejected - " + files[0].errors[0].code,
                {
                    level: 'error',
                    extra: {
                        error: files[0].errors[0].code,
                        form: props.form.id,
                        filename: files[0].file.name,
                        size: files[0].file.size,
                        type: files[0].file.type,
                    }
                }
            )

            setIsSnackbarOpened(true);
        }
    });

    return (
        <>
            <Button
                sx={{
                    py: 0.5,
                    px: 2,
                    background: theme.palette.text.primary,
                    color: "white",
                    transition: "transform 400ms",
                    ":hover": {
                        boxShadow: "rgba(0, 0, 0, 0.1)  0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                        transform: disabled || readOnly ? "none" : "translateY(-6px)",
                        background: "black"
                    }
                }}
                variant="contained"
                {...getRootProps()}>
                <input {...getInputProps()} />
                {props.children}
            </Button>

        <Snackbar
            sx={{
                maxWidth: '1200px',
                width: {
                    sm: '100%'
                },
                px: {
                    sm: 2
                },
                py: 'auto'
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isSnackbarOpened}
            autoHideDuration={5000}
            onClose={handleSnackbarClose} >
            <Alert
                sx={{
                    mx: {
                        sm: 2
                    },
                    my: 'auto',
                }}
                severity='error'
                iconMapping={{
                    success: <CheckFullIcon bgColor='white' color={plateformStyle.colors.green.main} />,
                    error: <CloseFullIcon bgColor='white' color={plateformStyle.colors.red.main} />,
                }}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleSnackbarClose}>
                        <MUICloseIcon fontSize="inherit" fontWeight={700} />
                    </IconButton>
                }>
                <Typography fontWeight={500}>{snackBarMessage}</Typography>
            </Alert>
        </Snackbar>
        </>
    )
}
