import {IContractSignature} from "../model/ContractSignature.model"


const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const signContract = (id: number, phone: String): Promise<IContractSignature> => {

    return fetch(`${apiUrl}/contract/${id}/sign?phone=${phone}`,
        {
            method: "GET",

    }).then(_ => _.json()) 
}


export const validateContractSignature = (id: number, code: String): Promise<IContractSignature> => {

    return fetch(`${apiUrl}/contract/signature/${id}/validate?code=${code}`,
        {
            method: "GET",

        }).then(_ => _.json())
}


export const createContract = (contract: IContractSignature): Promise<IContractSignature> => {

    return fetch(`${apiUrl}/contract/signature`,
        {
            method: "POST",
            headers: {"content-type": "application/json"},
            body: JSON.stringify(contract)

        }).then(_ => _.json())
}


export const modifyContract = (contract: IContractSignature): Promise<IContractSignature> => {

    return fetch(`${apiUrl}/contract/signature`,
        {
            method: "PUT",
            headers: {"content-type": "application/json"},
            body: JSON.stringify(contract)

        }).then(_ => _.json())
}

export const sendContractSignatureLink = (uuid: string, email: string): Promise<void> => {
    return fetch(`${apiUrl}/contract/form/${uuid}/signature/mail/${email}`,
        {
            method: "POST",
            headers: {"content-type": "application/json"},
        }).then(_ => {
        if (_.status === 200) return;
        else throw _.json()
    })
}
