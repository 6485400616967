import React, {forwardRef, Ref, useEffect, useImperativeHandle} from 'react';

import * as Sentry from '@sentry/react';
import {Severity} from '@sentry/react';

import Alert from '@mui/material/Alert';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import {FieldLabel} from '../Field/FieldLabel';
import {TextFieldFormatted} from '../Field/TextFieldFormatted';
import {CheckFullIcon} from '../Icon/CheckFullIcon';

import {signContract, validateContractSignature} from '../../api/ContractSignature.api';

import {IContractSignature} from '../../model/ContractSignature.model';

import {messageFieldRequired} from '../../utils';

import {colors} from '../../static/themes/colors';


interface Props {
    contractSignature: IContractSignature;
    contractId: number;
    plateformName: string;
    subscriberPhone: string;
    send: (contractSignature: IContractSignature) => void;
    goToNext: () => void;
    goToPrevious: () => void;
}


const codeMaxLength: number = 6;
const codeExpirationMinutes: number = 30;


export const FormSignValidate = forwardRef((props: Props, ref: Ref<any>) => {

    useImperativeHandle(ref, () => ({
        handleSubmit() {
            sendSignValidation();
        }
    }));

    const [code, setCode] = React.useState<string>("");
    const [codeError, setCodeError] = React.useState<string | undefined>();
    const [isSnackbarOpened, setIsSnackbarOpened] = React.useState<boolean>(false);

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    const sendSignRequest = () => {
        signContract(props.contractId, props.subscriberPhone.replace(/\+|\s|\(|\)/g, "")).then((response: IContractSignature) => {
            if (!response.id) {
                Sentry.captureMessage("sendSignRequest -> signContract - Exception",
                    {
                        level: 'error',
                        extra: {
                            response: response,
                            request: props.contractId?.toString(),
                            phone: props.subscriberPhone,
                        }
                    }
                );
            }

            props.send(response);
        }).catch(_ => {
            Sentry.captureException(_);
        });

        setIsSnackbarOpened(true);
    }

    const sendSignValidation = () => {
        if (!code) {
            setCodeError(messageFieldRequired);
            return;
        }

        if (code.length < codeMaxLength) {
            setCodeError("Code incomplet");
            return;
        }

        if (props.contractSignature.dateSent && new Date(props.contractSignature.dateSent) > new Date(Date.now() + codeExpirationMinutes * 60000)) {
            setCodeError("Code expiré");
            return;
        }

        if (props.contractSignature.id === undefined)
            return;

        validateContractSignature(props.contractSignature.id, code)
        .then((response: IContractSignature) => {
            if (!response.dateSigned || response.dateSigned === null) {

                if (response.dateSent && new Date(response.dateSent) > new Date(Date.now() + codeExpirationMinutes * 60000))
                    setCodeError("Code expiré");
                else
                    setCodeError("Code incorrect");

                return;
            }

            props.send(response);
            props.goToNext();
        });
    }


    useEffect(() => {
        if (props.contractSignature.dateSigned)
            props.goToNext();

        // Send the SMS if not sent or expired.  // FIXME : timezone KO
        if (!props.contractSignature.dateSent || new Date(props.contractSignature?.dateSent) > new Date(Date.now() + codeExpirationMinutes * 60000))
            sendSignRequest();
    }, [])


    return (
        <Grid
            container
            spacing={3}
            sx={{ textAlign: 'left' }} >
            <Grid
                item
                xs={12}
                sm={6} >
                <FormLabel error={codeError !== undefined} >
                    <FieldLabel label="Code reçu" isRequired />
                </FormLabel>
                <TextFieldFormatted
                        id="code"
                        fullWidth
                        value={code}
                        onChange={(e, value) => {
                            setCode(value);
                            setCodeError(undefined);
                        }}
                        error={codeError !== undefined}
                        helperText={codeError}
                        textLength={codeMaxLength}
                        toUpperCase />
            </Grid>
            <Grid
                item
                xs={12} >
                <Link
                    component='button'
                    onClick={() => {
                        sendSignRequest();
                    }}
                    sx={{
                        mb: 2,
                        textAlign: 'left'
                    }} >
                    Vous n’avez pas reçu le code ? Cliquez sur ce lien pour en recevoir un nouveau !
                </Link>
                <br />
                <Link
                    component='button'
                    onClick={() => {
                        props.goToPrevious();
                    }}
                    sx={{
                        mb: 2,
                        textAlign: 'left'
                    }} >
                    Vérifier votre numéro de téléphone.
                </Link>
            </Grid>

            <Snackbar
                sx={{
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarOpened}
                autoHideDuration={5000}
                onClose={handleSnackbarClose} >
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                        boxShadow: 3
                    }}
                    severity='success'
                    iconMapping={{
                        success: <CheckFullIcon color='white' bgColor={colors.green.main} />,
                    }} >
                    <Typography fontWeight={500} >Le code que vous devez saisir vous a été envoyé par SMS. Il est valable pendant {codeExpirationMinutes} min.</Typography>
                </Alert>
            </Snackbar>
        </Grid>
    )
})
