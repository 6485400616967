import { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Alert, { AlertColor } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import Grid from "@mui/material/Grid";
import Link from '@mui/material/Link';
import Portal from '@mui/material/Portal';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";

import { default as MUICloseIcon} from '@mui/icons-material/Close';

import { FieldLabel } from '../Field/FieldLabel';
import { TextFieldFormatted } from '../Field/TextFieldFormatted';
import { TextFieldPhone } from '../Field/TextFieldPhone';
import { CloseFullIcon } from '../Icon/CloseFullIcon';
import { CloseIcon } from '../Icon/CloseIcon';
import { CheckFullIcon } from '../Icon/CheckFullIcon';
import { OfferLeafIcon } from '../Icon/OfferLeafIcon';

import { IFormuleDetails } from '../../model/Formule.model';
import { IPerson } from '../../model/Person.model';

import { messageFieldRequired } from '../../utils/messages';
import { TransitionSlideUP } from '../../utils/transistions/transitions';

import { modifyPerson } from '../../api/Person.api';
import { createContractEstimation } from '../../api/ContractEstimation.api';
import { enumOfferLabels } from '../../utils/enums';
import * as Sentry from "@sentry/react";

interface Props {
    plateformStyle: any
    isOpened: boolean
    contractId: number
    offerCode?: string
    formulesSelected?: number[]
    formules?: IFormuleDetails[]
    subscriber: IPerson
    sendSubscriber: (subscriber: IPerson) => void
    handleClose: () => void
}


export const DialogDevis = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));
    const screenDisplay3 = useMediaQuery('(min-width:700px)');
    const screenDisplay2 = useMediaQuery('(min-width:500px)');

    const plateformStyle: any = props.plateformStyle;
    const offerStyle: any = plateformStyle.components.Offer;
    const iconHeaderStyle: any = plateformStyle.components.IconHeader;
    const buttonSubmitStyle: any = plateformStyle.components.Button.submit;

    const [isDialogSubscriberOpened, setIsDialogSubscriberOpened] = useState<boolean>(true);
    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>();
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("success");

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    useEffect(() => {
        if (props.isOpened) {
            setIsDialogSubscriberOpened(false)
            formik.setFieldValue("formulas", initialValues.formulas)
            formik.setFieldTouched("formulas", false)
            formik.setFieldValue("isConsent", initialValues.isConsent)
            formik.setFieldTouched("isConsent", false)
            formik.setFieldValue("isConsent2", initialValues.isConsent)
            formik.setFieldTouched("isConsent2", false)
        }
    }, [props.isOpened])

    
    interface FormValues {
        formulas: number[],
        subscriberLastname?: string,
        subscriberFirstname?: string,
        subscriberEmail?: string,
        subscriberPhone?: string,
        isConsent: boolean,
        isConsent2: boolean
    }

    const initialValues: FormValues = {
        formulas: props.formulesSelected || new Array() as number[],
        subscriberLastname: props.subscriber.lastname,
        subscriberFirstname: props.subscriber.firstname,
        subscriberEmail: props.subscriber.email,
        subscriberPhone: props.subscriber.phoneMobile,
        isConsent: false,
        isConsent2: false
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: yup.object({
            formulas: yup.array().of(yup.number())
                .min(1, "Vous devez sélectionner au moins une formule")
                .max(3, "Vous ne pouvez pas sélectionner plus de 3 formules"),
            subscriberLastname: yup.string().nullable().required(messageFieldRequired),
            subscriberFirstname: yup.string().nullable().required(messageFieldRequired),
            subscriberEmail: yup.string().email("Email non valide").nullable().required(messageFieldRequired),
            subscriberPhone: yup.string().matches(/((\+?)33|32)(\s*\d){9}\s*$/g, 'Format de téléphone non valide').nullable().required(messageFieldRequired),
            isConsent: yup.boolean().isTrue("Consentement obligatoire"),
            isConsent2: yup.boolean().isTrue("Consentement obligatoire")
        }),
        onSubmit: (values) => {
            const toSend: IPerson = {...props.subscriber};
            toSend.lastname = values.subscriberLastname;
            toSend.firstname = values.subscriberFirstname;
            toSend.email = values.subscriberEmail;
            toSend.phoneMobile = formik.values.subscriberPhone?.replace(/\s/g, "");
            if (toSend.phoneMobile && toSend.phoneMobile[0] !== "+") toSend.phoneMobile = "+" + toSend.phoneMobile;

            modifyPerson(toSend)
            .then((response: IPerson) => {
                if (!response.id) {
                    Sentry.captureMessage("DialogDevis -> onSubmit -> modifyPerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: toSend,
                            }
                        }
                    );
                    throw new Error();
                }

                props.sendSubscriber(response);

                // IMPORTANT : Do not wait for the createContractEstimation response, Henner take too long to responsed (20sec).
                setSnackbarMessage("Votre devis vient de vous être envoyé à l'adresse : " + response.email)
                setSnackbarSeverity("success")
                setIsSnackbarOpened(true)
                props.handleClose()

                return createContractEstimation({contractId: props.contractId, formulas: values.formulas.map(_ => _.toString())})
            })
            .then((response: any) => {
                if (!response.id) {
                    Sentry.captureMessage("DialogDevis -> onSubmit -> createContractEstimation - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: props.contractId,
                            }
                        }
                    );
                    throw new Error();
                }
            })
            .catch(exception => {
                if (exception.name === 'AbortError') {
                    return
                }
                setSnackbarMessage("Une erreur est survenue lors de la création de votre devis. Merci de réessayer ultérieurement.")
                setSnackbarSeverity("error")
                setIsSnackbarOpened(true)
            });
        }
    });

    return (
        <>
        <Dialog
            fullWidth
            maxWidth="lg"
            fullScreen={!screenSizeUpSM}
            TransitionComponent={screenSizeUpSM ? undefined : TransitionSlideUP}
            open={props.isOpened}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === "escapeKeyDown")
                    return;

                props.handleClose();
            }}>
            <IconButton
                sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                }}
                onClick={() => {
                    props.handleClose()
                }}>
                <CloseIcon color={plateformStyle.colors.black.main}/>
            </IconButton>

            {!isDialogSubscriberOpened ?
            <>
            <DialogTitle>
                <Typography variant="h2">Obtenez votre devis !</Typography>
                <Typography fontWeight={500}>Sélectionnez jusqu'à 3 formules à intégrer sur votre devis.</Typography>
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    justifyContent="stretch"
                    alignItems="stretch">
                        
                    {props.offerCode &&
                    <Grid
                        xs={12}
                        item>
                        <Typography variant='body2'>
                            {enumOfferLabels.find(_ => _.value === props.offerCode)?.label}
                        </Typography>
                    </Grid>}

                    {props.formules?.map(formule => {

                    const price = formule.formule.beneficiaires ? formule.formule.beneficiaires.map(b => b.tarif).reduce((x, y) => x + y) : 0;
                    const selected = formik.values.formulas.includes(formule.formule.identifiant)
                    const disabled = formik.values.formulas.length === 3 && !selected

                    return (
                    <Grid
                        md={6}
                        xs={12}
                        item
                        sx={{
                            height: "inherit"
                        }}>
                        <Card
                            sx={{
                                minHeight: "calc(100% - 32px - 4px)",  // 100% minus Grid padding and border width.
                                p: 2,
                                border: 2,
                                borderColor: selected ? iconHeaderStyle.color : plateformStyle.colors.grey.secondary,
                                ":hover": disabled ? {} : {
                                    cursor: "pointer",
                                    background: plateformStyle.colors.grey.main,
                                    borderColor: !selected && plateformStyle.colors.grey.main,
                                },
                                height: "fit-content",
                                opacity: disabled ? 0.6 : 1
                            }}
                            onClick={(e) => {
                                e.stopPropagation();

                                if (disabled) return

                                let f = [...formik.values.formulas]

                                if (f.includes(formule.formule.identifiant))
                                    f = f.filter(_ => _ !== formule.formule.identifiant)
                                else
                                    f.push(formule.formule.identifiant)

                                formik.setFieldValue("formulas", f)
                            }}>
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center">
                                <Grid
                                    item
                                    xs="auto">
                                    <Checkbox
                                        disabled={disabled}
                                        checked={selected}
                                        sx={{
                                            [`&, &.${checkboxClasses.checked}`]: {
                                                color: iconHeaderStyle.color,
                                            },
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 32
                                            }
                                        }}
                                        />
                                </Grid>
                                <Grid
                                    item
                                    xs>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center">
                                        <Grid
                                            item
                                            xs="auto"
                                            sx={{
                                                display: {
                                                    sm: "inline-flex",
                                                    xs: "none"
                                                }
                                            }}>
                                            <OfferLeafIcon
                                                color={formule.color}
                                                height='60px' />
                                        </Grid>
                                        <Grid
                                            item
                                            xs>
                                            <Typography variant='h3' color={formule.color}>{formule.formule.formuleLibelle}</Typography>
                                            <Stack
                                                direction="row"
                                                alignItems="flex-end"
                                                columnGap={1}
                                                useFlexGap
                                                flexWrap="wrap">
                                                <Typography variant='h3' noWrap >{price.toFixed(2)} €/mois</Typography>
                                                <Typography variant='caption' noWrap >Soit {(price * 12).toFixed(2)} €/an</Typography>
                                            </Stack>
                                            {formule.isRecommended &&
                                            <Box
                                                sx={{
                                                    mt: 1
                                                }}>
                                                <Typography
                                                    component='span'
                                                    noWrap
                                                    sx={{
                                                        px: 2,
                                                        py: 0.5,
                                                        borderRadius: 4,
                                                        color: "white",
                                                        background: offerStyle.recommendedColor
                                                    }} >
                                                    Recommandée
                                                    <Typography component='span' sx={{
                                                        display: {
                                                            sm: "inline",
                                                            xs: "none"
                                                        }
                                                    }}> pour vous</Typography>
                                                </Typography>
                                            </Box>}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>)})}
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{
                    p: 2,
                    pt: 1,
                    justifyContent: "center"
                }}>
                <Grid
                    container
                    spacing={{
                        md: 2,
                        xs: 1
                    }}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        textAlign: {
                            sm: "center",
                            xs: "left"
                        }
                    }}>
                    {formik.errors.formulas &&
                    <Grid
                        item
                        xs={12}>
                        <Typography
                            variant='caption'
                            component='div'
                            color="error"
                            fontSize='0.9rem'>
                            {formik.errors.formulas}
                        </Typography>
                    </Grid>}

                    <Grid
                        item
                        xs={12}>
                        <Button
                            disabled={formik.errors.formulas !== undefined}
                            variant="contained"
                            color={buttonSubmitStyle.color}
                            sx={{
                                width: {
                                    sm: 400,
                                    xs: '100%'
                                },
                                px: 5,
                                color: 'white'
                            }}
                            onClick={() => {
                                if (!formik.errors.formulas)
                                    setIsDialogSubscriberOpened(true)
                            }} >
                            Continuer
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
            </>
            :

            <>
            <DialogTitle>
                <Typography variant="h2">Obtenez votre devis !</Typography>
                <Typography fontWeight={500}>Veuillez compléter vos informations pour générer votre devis.</Typography>
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    justifyContent="flex-start">

                    <Grid
                        item
                        xs={12}>
                        <Grid
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}>
                            {props.offerCode &&
                            <Grid
                                item
                                xs={12}>
                                <Typography variant='body2'>
                                    {enumOfferLabels.find(_ => _.value === props.offerCode)?.label}
                                </Typography>
                            </Grid>}

                            {props.formules?.filter(_ => formik.values.formulas.includes(_.formule.identifiant)).map((_, id) => {
                            if (id >= 2 && !screenDisplay3) return <></>
                            if (id >= 1 && !screenDisplay2) return <></>
                            return (
                            <Grid
                                item
                                xs="auto">
                                <Card sx={{p: 1}}>
                                    <Typography variant='h3' color={_.color}>{_.formule.formuleLibelle}</Typography>
                                </Card>
                            </Grid>)
                            })}
                            {((!screenDisplay3 && formik.values.formulas.length > 2) ||
                              (!screenDisplay2 && formik.values.formulas.length > 1)) &&
                            <Grid
                                item
                                xs="auto">
                                <Card sx={{p: 1}}>
                                    <Typography variant='h3'>+{screenDisplay2 ? formik.values.formulas.length - 2 : formik.values.formulas.length - 1}</Typography>
                                </Card>
                            </Grid>}
                            <Grid
                                item
                                xs="auto">
                                <Link fontWeight={500} onClick={() => setIsDialogSubscriberOpened(false)}>modifier</Link>
                            </Grid>
                        </Grid>
                        
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={12}>
                        <FormLabel error={formik.touched.subscriberEmail && Boolean(formik.errors.subscriberEmail)} >
                            <FieldLabel label="Votre email" isRequired />
                        </FormLabel>
                        <Typography
                            variant='caption'
                            component='div'
                            fontSize='0.9rem'>
                            Votre email sera utilisé pour vous envoyer votre devis.
                        </Typography>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="subscriberEmail"
                            name="subscriberEmail"
                            value={formik.values.subscriberEmail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.subscriberEmail && Boolean(formik.errors.subscriberEmail)}
                            helperText={formik.touched.subscriberEmail && formik.errors.subscriberEmail} />
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={12}
                        sx={{
                            display: {
                                sm: "inline-flex",
                                xs: "none"
                            }
                        }}>
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={12}>
                        <FormLabel error={formik.touched.subscriberLastname && Boolean(formik.errors.subscriberLastname)} >
                            <FieldLabel label="Nom" isRequired />
                        </FormLabel>
                        <TextFieldFormatted
                            id="subscriberLastname"
                            fullWidth
                            toUpperCase
                            charactersEnabled="[^A-zÀ-ú\s-]"
                            textLength={100}
                            value={formik.values.subscriberLastname}
                            onChange={(e, value) => formik.setFieldValue("subscriberLastname", value)}
                            onBlur={(e: any) => formik.setFieldTouched("subscriberLastname")}
                            error={formik.touched.subscriberLastname && Boolean(formik.errors.subscriberLastname)}
                            helperText={formik.touched.subscriberLastname && formik.errors.subscriberLastname}
                            />
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={12}>
                        <FormLabel error={formik.touched.subscriberFirstname && Boolean(formik.errors.subscriberFirstname)} >
                            <FieldLabel label="Prénom" isRequired />
                        </FormLabel>
                        <TextFieldFormatted
                            id="subscriberFirstname"
                            fullWidth
                            capitalize
                            charactersEnabled="[^A-zÀ-ú\s-]"
                            textLength={100}
                            value={formik.values.subscriberFirstname}
                            onChange={(e, value) => formik.setFieldValue("subscriberFirstname", value)}
                            onBlur={(e: any) => formik.setFieldTouched("subscriberFirstname")}
                            error={formik.touched.subscriberFirstname && Boolean(formik.errors.subscriberFirstname)}
                            helperText={formik.touched.subscriberFirstname && formik.errors.subscriberFirstname}
                            />
                    </Grid>

                    <Grid
                        item
                        md={6}
                        xs={12}>
                        <FormLabel error={formik.touched.subscriberPhone && Boolean(formik.errors.subscriberPhone)} >
                            <FieldLabel label="Votre téléphone" isRequired />
                        </FormLabel>
                        <TextFieldPhone
                            id="subscriberPhone"
                            fullWidth
                            value={formik.values.subscriberPhone}
                            onChange={(value) => formik.setFieldValue("subscriberPhone", value)}
                            error={formik.touched.subscriberPhone && Boolean(formik.errors.subscriberPhone)}
                            helperText={formik.touched.subscriberPhone && formik.errors.subscriberPhone} />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.isConsent}
                                    onChange={e => formik.setFieldValue("isConsent", !formik.values.isConsent)}
                                    color="success"
                                    sx={{
                                        color: formik.touched.isConsent && Boolean(formik.errors.isConsent) ? plateformStyle.colors.red.main : plateformStyle.colors.black.secondary,
                                        pt: 0,
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 32
                                        }
                                    }} />}
                            label={
                                <Typography
                                    fontWeight={500}
                                    color={formik.touched.isConsent && Boolean(formik.errors.isConsent) ? "error" : "inherit"}
                                    sx={{mt: 0.5}}>
                                    J’accepte de recevoir des informations commerciales sur les produits et services proposés par la Mutuelle GSMC.
                                </Typography>
                            } />

                        {formik.touched.isConsent && Boolean(formik.errors.isConsent) &&
                        <FormHelperText
                            error={true}
                            sx={{
                                ml: 5,
                                mb: 1
                            }} >
                            <>{formik.errors.isConsent}</>
                        </FormHelperText>}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formik.values.isConsent2}
                                    onChange={e => formik.setFieldValue("isConsent2", !formik.values.isConsent2)}
                                    color="success"
                                    sx={{
                                        color: formik.touched.isConsent2 && Boolean(formik.errors.isConsent2) ? plateformStyle.colors.red.main : plateformStyle.colors.black.secondary,
                                        pt: 0,
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 32
                                        }
                                    }} />}
                            label={
                                <Typography
                                    fontWeight={500}
                                    color={formik.touched.isConsent2 && Boolean(formik.errors.isConsent2) ? "error" : "inherit"}
                                    sx={{mt: 0.5}}>
                                    J’accepte de recevoir des informations commerciales sur les produits et services proposés par les partenaires de la Mutuelle GSMC.
                                </Typography>
                            } />

                        {formik.touched.isConsent2 && Boolean(formik.errors.isConsent2) &&
                            <FormHelperText
                                error={true}
                                sx={{
                                    ml: 5,
                                    mb: 1
                                }} >
                                <>{formik.errors.isConsent2}</>
                            </FormHelperText>}
                        <Typography
                            variant='caption'
                            component='div'
                            fontSize='0.9rem'
                            sx={{ mY: 1, pt: 2, textAlign: 'justify' }}>
                            Les informations recueillies dans ce formulaire sont nécessaires à GSMC pour traiter votre demande de devis. Vous pouvez exercer vos droits d’accès, de suppression, de rectification des données auprès du Délégué à la protection des données par courrier adressé à « Mutuelle GSMC – Service DPO – 95 Rue de Jemmapes – 59000 Lille » ou via votre espace personnel.
                        </Typography>
                        <Typography
                            variant='caption'
                            component='div'
                            fontSize='0.9rem'
                            sx={{ mt: 1 }}>
                            Pour plus d'information, consulter notre rubrique{' '}
                            <Link
                                variant="caption"
                                fontWeight={500}
                                href="https://www.mutuelle-gsmc.fr/rgpd"
                                target="_blank">
                                Protection des données personnelles
                            </Link>.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{
                    p: 2,
                    pt: 1,
                    justifyContent: "center"
                }}>
                <Grid
                    container
                    spacing={{
                        md: 2,
                        xs: 1
                    }}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        textAlign: "center"
                    }}>
                    <Grid
                        item
                        xs={12}>
                        <Button
                            variant="contained"
                            color={buttonSubmitStyle.color}
                            sx={{
                                width: {
                                    sm: 400,
                                    xs: '100%'
                                },
                                px: 5,
                                color: 'white'
                            }}
                            onClick={() => {
                                formik.handleSubmit()
                            }} >
                            Recevoir mon devis
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
            </>}
        </Dialog>

        <Portal>
            <Snackbar
                sx={{
                    zIndex: 10000,
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarOpened}
                autoHideDuration={5000}
                onClose={handleSnackbarClose} >
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                    }}
                    severity={snackbarSeverity as AlertColor}
                    iconMapping={{
                        success: <CheckFullIcon bgColor='white' color={plateformStyle.colors.green.main} />,
                        error: <CloseFullIcon bgColor='white' color={plateformStyle.colors.red.main} />,
                    }}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleSnackbarClose}>
                            <MUICloseIcon fontSize="inherit" fontWeight={700} />
                        </IconButton>
                    }>
                    <Typography fontWeight={500}>{snackbarMessage}</Typography>
                </Alert>
            </Snackbar>
        </Portal>
        </>
    )
}
