import { useEffect, useState } from 'react';

import AnimatedNumber from "animated-number-react";

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { DialogOfferDetails } from '../Dialog/DialogOfferDetails';
import { InfoIcon } from '../Icon/InfoIcon';


import { getFormuleDetails, IFormule, IFormuleDetails } from '../../model/Formule.model';
import {IWarrantiesSection} from "../../model/Waranties.model";


interface Props {
    plateformStyle: any;
    codeOffer?: string;
    formule?: IFormule;
    formuleLoading?: Boolean;
    color?: string;
}


export const HeaderOffer = (props: Props) => {
    const iconStyle: any = props.plateformStyle?.components.IconHeader;

    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [price, setPrice] = useState<number>(props.formule?.beneficiaires ? props.formule.beneficiaires.map(b => b.tarif).reduce((x, y) => x + y) : 0);
    const [displayFormuleDetails, setDisplayFormuleDetails] = useState<boolean>(false);

    const plateformStyle: any = props.plateformStyle;
    const offerStyle: any = plateformStyle.components.Offer;
    const progressBarStyle: any = plateformStyle.components.ProgressBar;

    const formuleDetails: IFormuleDetails|undefined = props.formule ? getFormuleDetails(props.formule, plateformStyle) : undefined;
    const color: string|undefined = formuleDetails?.color || props.color || undefined;


    useEffect(() => {
        setIsInitialized(true)
    }, [])


    useEffect(() => {
        if (props.formule) {
            if (!props.formule.beneficiaires)
                setPrice(0);
            else {
                setPrice(props.formule.beneficiaires.map(b => b.tarif).reduce((x, y) => x + y));
            }
        }
    }, [props.formule])


    return (
        <Grid
            container>
            <Grid
                item
                xs={12}>
                <LinearProgress
                    value={100}
                    sx={{
                        display: props.formuleLoading ? 'block' : 'none',
                        width: "100%",
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: color || progressBarStyle.color,
                        }
                    }} />
            </Grid>

            <Grid
                item
                xs={12}>
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        p: 1,
                        pl: 2,
                        borderBottom: 1,
                        borderColor: props.plateformStyle.colors.grey.main
                    }}>
                    <Grid
                        item
                        xs>
                        <Typography variant='h3' color={color || offerStyle.color} >{props.formule?.formuleLibelle}</Typography>
                        <Grid
                            container
                            columnSpacing={1}
                            justifyContent='flex-start'
                            alignItems='flex-end' >
                            <Grid
                                item >
                                <Typography variant='h3' component='div' noWrap >
                                    <AnimatedNumber
                                        value={price}
                                        formatValue={(value: number) => value.toFixed(2)}
                                        duration={!isInitialized ? 0 : 500}
                                    />€/mois
                                </Typography>
                            </Grid>
                            <Grid
                                item >
                                <Typography variant='caption' component='div' noWrap >Soit <AnimatedNumber
                                        value={price * 12}
                                        formatValue={(value: number) => value.toFixed(2)}
                                        duration={!isInitialized ? 0 : 500}
                                    />€/an
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs="auto">
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => setDisplayFormuleDetails(true)}>
                            <InfoIcon color={iconStyle?.color || "black"} />
                        </IconButton>
                    </Grid>
                </Grid>

                {formuleDetails && props.codeOffer &&
                <DialogOfferDetails
                    plateformStyle={plateformStyle}
                    codeOffer={props.codeOffer}
                    formuleDetails={formuleDetails}
                    isDisplayed={displayFormuleDetails}
                    onClose={() => setDisplayFormuleDetails(false)}/> }
            </Grid>
        </Grid>
    )
}
